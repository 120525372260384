<template style="background-color: #f4f5f7">
  <div class="settings" style="width: 100%; background-color: #f4f5f7; padding-bottom: 56px;">

    <div style="z-index: 99;  background-color: #fff;" class="appBar android">
      <div style="flex:3; background-color: #fff; width: 100%; text-align: left; display: flex; height: 50px;">
        <router-link class="appBarLogoText" :to="prefixPath" style="text-align: left;"><img class="app-logo" src="https://gcdn.bionluk.com/site/general/bionluk_logo_gk.png"></router-link>
      </div>
    </div>

    <div :class="'underAppBar ' + deviceType"></div>
    <ul class="nav-list" style=" width: auto; margin: 0!important; padding: 0;">
      <li :class="deviceType + ' li'">
        <router-link :to = "'/panel/hatirlatma'" active-class="active" exact style="padding-left: 0; box-shadow:none;" >
          <div class="list-item">
            <div class="list-item-text">
              <div class="list-item-text-image-section">
                <img class="list-item-text-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_todo.svg'" alt="img">
                <div v-show="user.todoCount" class="list-item-text-image-badge">
                  <span class="list-item-text-image-badge-value">{{ user.todoCount }}</span>
                </div>
              </div>
              <div class="list-item-text-info">
                <h3 class="list-item-text-info-header">Yapılacaklar</h3>
                <span class="list-item-text-info-description">İş akışını takip edin</span>
              </div>
            </div>
            <div class="list-item-arrow">
              <img class="list-item-arrow-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_ok.svg'" alt="">
            </div>
          </div>
        </router-link>
      </li>

      <li :class="deviceType + ' li'">
        <router-link :to = "'/panel/mesajlar'" active-class="active" exact style="padding-left: 0; box-shadow:none;">
          <div class="list-item">
            <div class="list-item-text">
              <div class="list-item-text-image-section">
                <img class="list-item-text-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_messages.svg'" alt="img">
                <div v-show="user.unreadMessageCount" class="list-item-text-image-badge">
                  <span class="list-item-text-image-badge-value">{{ user.unreadMessageCount }}</span>
                </div>
              </div>
              <div class="list-item-text-info">
                <h3 class="list-item-text-info-header">Mesajlarım</h3>
                <span class="list-item-text-info-description">Tüm mesajlar</span>
              </div>
            </div>
            <div class="list-item-arrow">
              <img class="list-item-arrow-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_ok.svg'" alt="">
            </div>
          </div>
        </router-link>
      </li>

      <li :class="deviceType + ' li'">
        <router-link :to = "'/panel/istekler'" active-class="active" exact style="padding-left: 0; box-shadow:none;">
          <div class="list-item">
            <div class="list-item-text">
              <div class="list-item-text-image-section">
                <img class="list-item-text-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_request.svg'" alt="img">
              </div>
              <div class="list-item-text-info">
                <h3 class="list-item-text-info-header">Özel Alıcı İsteklerim</h3>
                <span class="list-item-text-info-description">Özel isteklerin</span>
              </div>
            </div>
            <div class="list-item-arrow">
              <img class="list-item-arrow-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_ok.svg'" alt="">
            </div>
          </div>
        </router-link>
      </li>

      <li :class="deviceType + ' li'">
        <router-link :to = "'/panel/siparisler'" active-class="active" exact style="padding-left: 0; box-shadow:none;">
          <div class="list-item">
            <div class="list-item-text">
              <div class="list-item-text-image-section">
                <img class="list-item-text-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_orders.svg'" alt="img">
              </div>
              <div class="list-item-text-info">
                <h3 class="list-item-text-info-header">Aldıklarım</h3>
                <span class="list-item-text-info-description">Satın aldığın işler</span>
              </div>
            </div>
            <div class="list-item-arrow">
              <img class="list-item-arrow-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_ok.svg'" alt="">
            </div>
          </div>
        </router-link>
      </li>


      <li :class="deviceType + ' li'">
        <router-link :to = "'/panel/listelerim'" active-class="active" exact style="padding-left: 0; box-shadow:none;">
          <div class="list-item">
            <div class="list-item-text">
              <div class="list-item-text-image-section">
                <img class="list-item-text-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_favs.svg'" alt="img">
              </div>
              <div class="list-item-text-info">
                <h3 class="list-item-text-info-header">Favorilerim</h3>
                <span class="list-item-text-info-description">Kaydettiğin iş ilanları</span>
              </div>
            </div>
            <div class="list-item-arrow">
              <img class="list-item-arrow-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_ok.svg'" alt="">
            </div>
          </div>
        </router-link>
      </li>

      <li :class="deviceType + ' li'">
        <router-link :to = "'/panel/finans'" active-class="active" exact style="padding-left: 0; box-shadow:none;">
          <div class="list-item">
            <div class="list-item-text">
              <div class="list-item-text-image-section">
                <img class="list-item-text-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_finance.svg'" alt="img">
              </div>
              <div class="list-item-text-info">
                <h3 class="list-item-text-info-header">Finans</h3>
                <span class="list-item-text-info-description">Ödeme geçmişin</span>
              </div>
            </div>
            <div class="list-item-arrow">
              <img class="list-item-arrow-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_ok.svg'" alt="">
            </div>
          </div>
        </router-link>
      </li>




      <div v-if="user.seller.isSeller">


        <li :class="deviceType + ' li'">
          <router-link :to = "'/panel/bicoin'" active-class="active" exact style="padding-left: 0; box-shadow:none;">
            <div class="list-item">
              <div class="list-item-text">
                <div class="list-item-text-image-section">
                  <img class="list-item-text-image" src="https://gcdn.bionluk.com/site/icon/mobil_bicoin.svg" alt="img">
                </div>
                <div class="list-item-text-info">
                  <h3 class="list-item-text-info-header">bicoin Hesabım</h3>
                  <span class="list-item-text-info-description">bicoin'lerini Yönet</span>
                </div>
              </div>
              <div class="list-item-arrow">
                <img class="list-item-arrow-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_ok.svg'" alt="">
              </div>
            </div>
          </router-link>
        </li>



        <li  v-if="user.seller.isSeller" :class="deviceType + ' li'">
          <router-link :to = "'/panel/satislar'" active-class="active" exact style="padding-left: 0; box-shadow:none;">
            <div class="list-item">
              <div class="list-item-text">
                <div class="list-item-text-image-section">
                  <img class="list-item-text-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_orders.svg'" alt="img">
                </div>
                <div class="list-item-text-info">
                  <h3 class="list-item-text-info-header">Satışlarım</h3>
                  <span class="list-item-text-info-description">Satış yaptığın işler</span>
                </div>
              </div>
              <div class="list-item-arrow">
                <img class="list-item-arrow-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_ok.svg'" alt="">
              </div>
            </div>
          </router-link>
        </li>

        <li :class="deviceType + ' li'">
          <router-link :to = "'/panel/portfolyo'" active-class="active" exact style="padding-left: 0; box-shadow:none;">
            <div class="list-item">
              <div class="list-item-text">
                <div class="list-item-text-image-section">
                  <img class="list-item-text-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_background.svg'" alt="img">
                </div>
                <div class="list-item-text-info">
                  <h3 class="list-item-text-info-header">Portfolyom</h3>
                  <span class="list-item-text-info-description">Geçmiş projelerin</span>
                </div>
              </div>
              <div class="list-item-arrow">
                <img class="list-item-arrow-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_ok.svg'" alt="">
              </div>
            </div>
          </router-link>
        </li>

        <li :class="deviceType + ' li'">
          <router-link :to = "'/panel/ilanlar'" active-class="active" exact style="padding-left: 0; box-shadow:none;">
            <div class="list-item">
              <div class="list-item-text">
                <div class="list-item-text-image-section">
                  <img class="list-item-text-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_deeds.svg'" alt="img">
                </div>
                <div class="list-item-text-info">
                  <h3 class="list-item-text-info-header">İş İlanlarım</h3>
                  <span class="list-item-text-info-description">Kategori ilanların</span>
                </div>
              </div>
              <div class="list-item-arrow">
                <img class="list-item-arrow-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_ok.svg'" alt="">
              </div>
            </div>
          </router-link>
        </li>

        <li :class="deviceType + ' li'">
          <router-link :to = "'/panel/alici-istekleri'" active-class="active" exact style="padding-left: 0; box-shadow:none;">
            <div class="list-item">
              <div class="list-item-text">
                <div class="list-item-text-image-section">
                  <img class="list-item-text-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_open_jobs.svg'" alt="img">
                </div>
                <div class="list-item-text-info">
                  <h3 class="list-item-text-info-header">Özel Alıcı İstekleri</h3>
                  <span class="list-item-text-info-description">Alıcı isteklerine teklif gönderin</span>
                </div>
              </div>
              <div class="list-item-arrow">
                <img class="list-item-arrow-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_ok.svg'" alt="">
              </div>
            </div>
          </router-link>
        </li>

        <!--
        <li :class="deviceType + ' li'">
          <router-link :to = "'/panel/verdigim-teklifler'" active-class="active" exact style="padding-left: 0; box-shadow:none;">
            <div class="list-item">
              <div class="list-item-text">
                <div class="list-item-text-image-section">
                  <img class="list-item-text-image" :src="$root.CDN_SITE + 'icon/tansel_chat_bottom_customoffer.svg'" alt="img">
                </div>
                <div class="list-item-text-info">
                  <h3 class="list-item-text-info-header">Tekliflerim</h3>
                  <span class="list-item-text-info-description">Verdiğin Teklifleri Görüntüle</span>
                </div>
              </div>
              <div class="list-item-arrow">
                <img class="list-item-arrow-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_ok.svg'" alt="">
              </div>
            </div>
          </router-link>
        </li>
        -->
      </div>

      <div v-else>
        <li :class="deviceType + ' li'">
          <router-link :to = "'/become_a_freelancer'" active-class="active" exact style="padding-left: 0; box-shadow:none;">
            <div class="list-item">
              <div class="list-item-text">
                <div class="list-item-text-image-section">
                  <img class="list-item-text-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_deeds.svg'" alt="img">
                </div>
                <div class="list-item-text-info">
                  <h3 class="list-item-text-info-header" style="padding-left: 0;color: #fd4056;">Freelancer Ol!</h3>
                  <span class="list-item-text-info-description">Topluluğumuza katılın</span>
                </div>
              </div>
              <div class="list-item-arrow">
                <img class="list-item-arrow-image" :src="$root.CDN_SITE + 'icon/tansel_mobil_ws_ok.svg'" alt="">
              </div>
            </div>
          </router-link>
        </li>
      </div>


    </ul>

  </div>
</template>

<script>

  import WorkstationNav from "../navComponent";

  export default {
    name: "src-pages-body-workstation-dashboard-v1_mobile",
    components: { WorkstationNav },
    data () {
      return { }
    }
  }
</script>

<style scoped>
  .settings {
    background-color: #FFF!important;
  }
  .nav-list{
    background-color: #FFF;
  }
  .chatrow{
    height: 80px; background-color: #ffffff;  display: flex; align-items: center;
    border-top: solid 1px #eaedf2;
  }

  .numberCircle {

    border-radius: 50%;
    width: 20px;

    background-color: #f2b46d;


  }
  .numberCircle span {

    text-align: center;
    line-height: 20px;
    padding-left: 1px;



    display: block;
    color: #fff;
    font-weight: 500;

  }



  .list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 9px 11.6px 10px 10.2px;
  }
  .li{
    position: relative;
    margin: 0 14.8px 0 15.2px;
  }
  .li::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #dfe4ec;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .list-item-text{
    display: flex;
  }

  .list-item-text-image-section{
    display: flex;
    align-items: center;
    position: relative;
  }

  .list-item-text-image{
    width: 24px;
    height: 24px;
    padding: 0;
  }

  .list-item-text-info{
    padding-left: 18px;
  }

  .list-item-text-info-header{
    font-size: 16px;
    font-weight: 600;
    color: #4b4f52;
  }
  .list-item-text-info-description{
    font-size: 14px;
    color: #8b95a1;
  }
  .list-item-arrow-image{
    padding: 0;
  }
  .list-item-text-image-badge{
    position: absolute;
    top: -50%;
    right: -25%;
    transform: translate(25%, 50%);
    width: 16px;
    height: 16px;
    background-color: #ff510b;
    border: 3px solid #FFF;
    border-radius: 100%;
  }
  .list-item-text-image-badge-value{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.2px;
    text-align: center;
    color: #FFF;
  }

  .appBar {
    z-index: 99;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
  }

  .app-logo{
    height: 28px;
    margin-top:2px;
    margin-left: 15px;
  }

</style>
